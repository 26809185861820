import React from "react"
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import Meta from "../components/meta"
import playIconImg from "../images/play_icon.png";
import logoAmdocsImg from "../images/logo - amdocs.png";
import logoNielsenImg from "../images/logo - Nielsen.png";
import logoTadiranImg from "../images/logo - tadiran.png";
import logoTelvistaImg from "../images/logo - telvista.png";
import logoYnetImg from "../images/logo - ynet.png";
import logoAllJobsImg from "../images/logo - jobs.png";
import logoFattalImg from "../images/logo - Fattal.png";
import handsHelpingIcon from "../images/Icon awesome-hands-helping.svg";
import brainIcon from "../images/Icon awesome-brain.svg";
import robotIcon from "../images/Icon awesome-robot.svg";
import graphicShadow from "../images/graphic shadow.svg";
import trendingUpIcon from "../images/Icon feather-trending-up.svg";
import doubleCheckIcon from "../images/Icon awesome-check-double.svg";
import speedometerIcon from "../images/Icon ionic-ios-speedometer.svg";
import bubbleStarIcon from "../images/Icon simple-micro-dot-blog.svg";
import faceImg1 from "../images/face 1.png";
import faceImg2 from "../images/face 2.png";
import faceImg3 from "../images/face 3.png";
import nasdaqFeaturedImg from "../images/featured on nasdaq.png";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const Homepage = ({data, location}) => {
    return data && (
        <Layout location={location}>
            <Meta title="Home"/>
            <div className="banner banner_home">
                <div className="wrapper">
                    <div className="banner_content">
                        <h1>Keep your sales team highly motivated and engaged<br/><strong>even from home</strong></h1>
                        <p>Enerjoy’s smart Sales Achievement Platform defeats sales team demotivation through a uniquely
                            incentivized approach, powered by AI.</p>
                        <a href="https://player.vimeo.com/video/318176461" className="all_about ShadowboxVideo"
                           data-rel="shadowbox;player=iframe;width=800;height=500">
                            <img src={playIconImg} alt="Play Video"/> See what it's all about
                        </a>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="trusted">
                    <h5>Trusted by</h5>
                    <div className="logo_list owl-carousel">
                        <div className="img_item">
                            <img src={logoAmdocsImg} alt="Amdocs"/>
                        </div>
                        <div className="img_item">
                            <img src={logoNielsenImg} alt="Nielsen"/>
                        </div>
                        <div className="img_item">
                            <img src={logoTadiranImg} alt="Tadiran"/>
                        </div>
                        <div className="img_item">
                            <img src={logoTelvistaImg} alt="Telvista"/>
                        </div>
                        <div className="img_item">
                            <img src={logoYnetImg} alt="Ynet"/>
                        </div>
                        <div className="img_item">
                            <img src={logoAllJobsImg} alt="AllJobs"/>
                        </div>
                        <div className="img_item">
                            <img src={logoFattalImg} alt="Fattal"/>
                        </div>
                    </div>
                </div>
                <div className="box_process cf">
                    <div className="box">
              <span style={{backgroundColor: '#9824C6'}}>
                  <img src={handsHelpingIcon} alt="hands"/>
              </span>
                        <h2>Wide range of incentives</h2>
                        <p>Cover all the essential elements for creating an energetic, engaged work environment in the
                            office
                            or at home: Transparency and clarity, a challenging experience, sense of reward,
                            recognition,
                            appreciation and positive communication</p>
                    </div>
                    <div className="box">
              <span style={{backgroundColor: '#FDD85B'}}>
                  <img src={robotIcon} alt="robot"/>
                  </span>
                        <h2>Motivational tools</h2>
                        <p>All the tools needed to control the incentivization, motivation and engagement process -
                            centralized for managers in one amazing platform: Goal management, dashboards, TV channels,
                            shift
                            management, communication channels with employees, incentivization program management, and
                            motivation budget management</p>
                    </div>
                    <div className="box">
              <span style={{backgroundColor: '#38EE7D'}}>
                  <img src={brainIcon} alt="brain"/>
              </span>
                        <h2>AI Technology</h2>
                        <p>Identifies milestones in an employee's progress toward his/her goals, learns his/her personal
                            motivational style and autonomously runs a personalized incentive program for him/her
                            throughout
                            his/her ongoing and challenging journey to achieving better business results</p>
                    </div>
                </div>
                <div className="team_member_wrap">
                    <h3>Turn Every Single Member of Your Team into a Higher Achiever</h3>
                    <h5>Enerjoy lifts the performance of every employee, not just the stars.</h5>
                </div>
            </div>
            <div className="face_img">
                <Img fluid={[
                    {
                        ...data.facesMobile.childImageSharp.fluid,
                        media: `(max-width: 959px)`
                    },
                    {
                        ...data.facesDesktop.childImageSharp.fluid,
                        media: `(min-width: 960px)`
                    }
                ]}/>
            </div>
            <div className="mo_img">
                <h5>Enerjoy lifts the performance of every employee, not just the stars.</h5>
            </div>
            <div className="wrapper">
                <div className="abo_text cf">
                    <div className="right_sec img_content">
                        <div className="explain_img text-right">
                            <Img fluid={data.graphic1.childImageSharp.fluid}/>
                            <span><img src={graphicShadow} alt="shadow"/></span>
                        </div>
                    </div>
                    <div className="left_sec text_content">
                        <h4>Since we started working with Enerjoy, my success rate increased dramatically.</h4>
                        <p>A lot of it is thanks to Enerjoy’s motivational challenges where I’m encouraged to
                            participate
                            rather than compete. This motivational style works for me.</p>
                        <div className="test_pepole">
                            <span><img src={faceImg1} alt="face"/></span> <h6>Yarin, Sales Manager</h6>
                        </div>
                    </div>
                </div>
                <div className="abo_text cf">
                    <div className="left_sec img_content">
                        <div className="explain_img text-left">
                            <Img fluid={data.graphic2.childImageSharp.fluid}/>
                            <span><img src={graphicShadow} alt="shadow"/></span>
                        </div>
                    </div>
                    <div className="right_sec text_content">
                        <h4>Enerjoy has revolutionized our work environment.</h4>
                        <p>Sales performance has increased by high percentage rates, employees are more engaged,
                            satisfied and
                            feel appreciated. The sales team is cooperative, motivated and energetic. All these factors
                            have
                            contributed to higher performance rates of each individual employee.</p>
                        <div className="test_pepole">
                            <span><img src={faceImg2} alt="face"/></span> <h6>Jana, Sales Manager</h6>
                        </div>
                    </div>
                </div>
                <div className="abo_text cf">
                    <div className="right_sec img_content">
                        <div className="explain_img text-right">
                            <Img fluid={data.graphic3.childImageSharp.fluid}/>
                            <span><img src={graphicShadow} alt="shadow"/></span>
                        </div>
                    </div>
                    <div className="left_sec text_content">
                        <h4>The opportunity to win challenges on a daily basis is really exciting.</h4>
                        <p>Particularly earning points, accumulating coins and redeeming them in the Joy store. Being
                            able to
                            choose my own rewards according to personal preferences is GREAT. The most amazing feature
                            is that
                            Enerjoy can determine who I would like to compete against and invites us both to check
                            in</p>
                        <div className="test_pepole">
                            <span><img src={faceImg3} alt="face"/></span> <h6>Jeni, Sales Rep</h6>
                        </div>
                    </div>
                </div>

                <div className="calculation_wrap cf">
                    <div className="counter_box co1">
                        <span><img src={trendingUpIcon} alt="higher sales revenue"/></span>
                        <h3>18<strong>%</strong></h3>
                        <h6>Higher <br/> sales revenue</h6>
                    </div>
                    <div className="counter_box co2">
                        <span><img src={doubleCheckIcon} alt="faster on boarding"/></span>
                        <h3>30<strong>%</strong></h3>
                        <h6>Faster <br/> onboarding</h6>
                    </div>
                    <div className="counter_box co3">
                        <span><img src={speedometerIcon} alt="higher employee productivity"/></span>
                        <h3>12<strong>%</strong></h3>
                        <h6>Higher <br/>employee productivity</h6>
                    </div>
                    <div className="counter_box co4">
                        <span><img src={bubbleStarIcon} alt="less employee attrition"/></span>
                        <h3>17<strong>%</strong></h3>
                        <h6>Less <br/> employee attrition</h6>
                    </div>
                </div>

                <div className="in_sales cf">
                    <div className="text_right">
                        <h3><span/> Want to increase your sales?</h3>
                    </div>
                    <div className="con_mail">
                        <RequestDemoMiniForm/>
                    </div>
                </div>
                <div className="nasdaq">
                    <a href="https://www.youtube.com/embed/QQvDGfh2m1s" className="all_about ShadowboxVideo"
                       data-rel="shadowbox;player=iframe;width=800;height=500">
                        <img src={nasdaqFeaturedImg} alt="featured on nasdaq"/>
                    </a>
                </div>
            </div>
        </Layout>
    );
}

export default Homepage;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const query = graphql`
    query {
        facesMobile: file(relativePath: { eq: "faces-mobile@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 959, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        facesDesktop: file(relativePath: { eq: "faces-line.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        graphic1: file(relativePath: { eq: "graphic-hp1.png" }) { ...fluidImage }
        graphic2: file(relativePath: { eq: "graphic-hp2.png" }) { ...fluidImage }
        graphic3: file(relativePath: { eq: "graphic-hp3.png" }) { ...fluidImage }
    }
`;